@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color) var(--scrollbar-track-color);
    scroll-behavior: smooth;
}

.bounce {
    animation: bounce 2s infinite;
    animation-delay: 0.5s;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

